<template>
  <div class="membership">
    <h1>会员权益</h1>
    <div class="main">
      <div class="title">升级成为会员 享受更多特权</div>
      <ul class="items">
        <li class="item">
          <div class="img fcc">
            <img src="@/assets/images/listeren.png" />
          </div>
          <span class="text">免费听所有辅导内容</span>
        </li>
        <li class="item">
          <div class="img fcc">
            <img src="@/assets/images/invite.png" />
          </div>
          <span class="text">邀请好友辅导，拿到辅导券</span>
        </li>
        <li class="item">
          <div class="img fcc">
            <img src="@/assets/images/unlock.png" />
          </div>
          <span class="text">解锁新内容</span>
        </li>
      </ul>
    </div>
    <div class="price" v-if="!isMember">
      <span>终生会员</span>
      <p class="num">888</p>
      <span>元</span>
    </div>
    <div class="buy-button" v-if="!isMember">购买</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMember: false,
    };
  },
};
</script>

<style lang="scss">
.membership {
  width: 620px;
  margin: 0 auto;
  .fcc {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1 {
    margin: 20px 0 21px;
    height: 35px;
    font-size: 24px;
    font-weight: 700;
  }
  .title {
    background-color: #143dce;
    color: #fff;
    height: 64px;
    line-height: 54px;
    border-radius: 8px;
    text-align: center;
  }
  .price {
    margin-top: 18px;
    width: 620px;
    height: 75px;
    line-height: 75px;
    border-radius: 8px;
    background: linear-gradient(90deg, #fff7f7 0%, #fff6f0 100%);
    border: 0px solid #143dce;
    font-size: 16px;
    font-weight: 500;
    color: rgba(212, 48, 48, 1);
    text-align: center;
    .num {
      display: inline-block;
      margin: 0 2px 0 8px;
      color: rgba(212, 48, 48, 1);
      font-size: 28px;
    }
  }
  .buy-button {
    margin-top: 70px;
    width: 620px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    color: #fff;
    background: #143dce;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
  }
  .main {
    position: relative;
    width: 620px;
    height: 255px;
    border-radius: 8px;
    background: #f7fbff;
    font-size: 16px;
    font-weight: 500;
    .items {
      position: absolute;
      top: 50px;
      padding-top: 21px;
      padding-bottom: 23px;
      width: 100%;
      border-radius: 8px;
      background-color: #f7fbff;
      .item {
        display: flex;
        align-items: center;
        margin-left: 50%;
        margin-bottom: 10px;
        width: 50%;
        transform: translateX(-40%);
      }
      .img {
        margin-right: 10px;
        width: 46px;
        height: 46px;
        background: linear-gradient(90deg, #1584cf 0%, #5779f7 100%);
        border-radius: 50%;
        img {
          width: 24px;
          height: 22px;
        }
      }
    }
  }
}
</style>
